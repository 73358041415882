import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import ProductAdvantages from "@/components/ProductAdvantages";
import AboutProduct from "@/components/AboutProduct";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
import PlansBox from "@/components/PlansBox";
import Button from "@/components/Button/Button";
export const _frontmatter = {
  "title": "Contabilidade Empresarial",
  "path": "/contabilidade",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation contactLink="/" knowMoreLink="https://www.instagram.com/leancontabilidade" description="Pensando nisso, contamos com um atendimento personalizado e uma equipe de especialistas sempre disponíveis para atender você de forma rápida, eficiente e humanizada." title={() => <>
      Vamos cuidar de toda a burocracia da sua{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        empresa
      </Typography>
      . Dessa forma, você consegue focar naquilo que realmente{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        importa
      </Typography>
      .
    </>} mdxType="ProductPresentation" />{" "}
    <AboutProduct stepThree={() => <Typography as="span" weight={700} variant="h3" mdxType="Typography">
      Contábil
    </Typography>} stepOne={() => <Typography as="span" weight={700} variant="h3" mdxType="Typography">
      Pessoal
    </Typography>} stepTwo={() => <Typography as="span" weight={700} variant="h3" mdxType="Typography">
      Fiscal
    </Typography>} items={[["Administração plena da folha de pagamento dos funcionários devidamente contratados", "Processos de admissão, rescisão e férias", "Emissão de Guias de recolhimento (GPS, FGTS, IRRF)", "Registro e atualização no livro ou ficha do empregado", "RAIS, CAGED, DIRF e obrigações ligadas à folha (SEFIP)", "Elaboração do e-Social"], ["Registro e escrituração dos documentos fiscais", "Apuração dos Impostos e elaboração das guias de pagamento", "Elaboração e entrega de obrigações acessórias", "Atendimento a fiscalizações"], ["Emissão e atualização de certidões, quando necessário", "Escrituração contábil de acordo com as normas e princípios vigentes", "Apuração de balancetes de verificação", "Elaboração das demonstrações financeiras", "Escrituração de livros contábeis"]]} openLabel="Não possuo CNPJ" openLink="https://wa.me/5581994019577?text=Olá, quero abrir minha empresa e preciso de ajuda." hasLabel="Já possuo CNPJ" hasLink="https://wa.me/5581994019577?text=Olá, já possuo empresa. Estou precisando de ajuda e gostaria de mais informações." knowMoreLink="https://www.instagram.com/leancontabilidade" mdxType="AboutProduct" />
    <InvestTime mdxType="InvestTime">
  <Typography align="center" color="primary" variant="h1" mdxType="Typography">
    Invista mais tempo no seu negócio{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      e
    </Typography>{" "}
    deixe a burocracia com a gente.{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      Faça já sua
    </Typography>{" "}
    contabilidade online.
  </Typography>
    </InvestTime>
    <ProductAdvantages description=" Você está perdendo dinheiro por não formalizar o seu negócio. Confira ao lado as vantagens de ter uma empresa e já saia na frente." items={["Formalização plena das atividades", "Ter CNPJ e Alvará", "Emitir Nota Fiscal", "Acessar linhas de crédito para PJ", "Possibilidade de vender para o Governo e outras Empresas", "Possibilidade de faturar acima de R$81 mil por ano", "Contribuição para a Previdência", "Formalização da sua equipe", "Se manter regularizado com a Receita Federal"]} title={() => <>
      Confira os principais{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        Benefícios
      </Typography>{" "}
      de ter uma{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        Empresa
      </Typography>
    </>} mdxType="ProductAdvantages" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      